<template>
  <div class="home">
    <div class="home-container">
      <div class="section section-1">

        <img class="heart" src="@/assets/heart.svg">
        <div class="headline-container">
          <h2 class="new-hope">
            <div class="tape"></div>
            Neue Hoffnung
          </h2>
          <h2>für dich.</h2>
        </div>

        <div id="scroll-hint" class="scroll-hint">
          <span class="hint">Scrollen</span>
          <span class="material-icons icon">expand_more</span>
        </div>

        <div id="question" class="question">
          <h2 id="question-question"><span/></h2>
        </div>

        <div class="section-1-diagonal"/>
      </div>

      <div class="section section-2">
        <div class="section-content">
          <h3 class="solution">Die Lösung</h3>
          <p class="solution-text">für unser Problem finden wir bei unserem Erfinder: Gott.<br>Er wurde vor rund 2000
            Jahren in <u class="underline">Jesus Christus</u> Mensch, um uns eine Lösung für unser Herzensproblem
            anzubieten.
          </p>
          <p class="solution-expand" :class="{expanded: solutionExpanded}" @click="expandSolution">Mehr dazu
            <span class="material-icons" style="transform: translateY(4px)">expand_more</span>
          </p>

          <p id="solution-text-long" class="solution-text-long" :class="{expanded: solutionExpanded}">
            Gott war immer schon da, er ist von Ewigkeiten her. Gott ist Liebe und <u class="underline">Liebe sucht sich
            ein Gegenüber</u>.
            Dieses Gegenüber bist du. Auch ich. Und die gesamte Menschheit. Aus Gottes Sicht wurden wir dazu geschaffen,
            um in liebevoller Gemeinschaft mit ihm und miteinander zu stehen.
            <br>
            <u class="underline">Wer liebt, gibt Freiheit.</u> Wer liebt, gibt die Wahlmöglichkeit, ob er von seinem
            Gegenüber zurück
            geliebt
            wird. Deshalb hat Gott uns Menschen mit einem freien Willen ausgestattet. Aber der Mensch wandte sich gegen
            Gott und wollte selbst der Chef sein. Macht haben. Und das am besten ohne Gott. (Wie wir das von uns
            Menschen kennen.) Gott ließ es zu - weil Liebe und Freiheit ihren Preis haben.
            <br>
            Mit der Loslösung von einem Gott, der die Liebe ist, <u class="underline">begann die Leidspirale</u> der
            Menschheit. Weil der
            Mensch
            seither selbst am Steuer seines Lebens sitzt und aus Machtgier, Egoismus, im Namen des Fortschritts oder der
            Religion Untaten verübt. Im Großen wie im privaten Kleinen.
            <br>
            Das Dilemma? Wir Menschen wollen ja nicht böse oder destruktiv sein. Wir können nur nicht anders. Schlag die
            Zeitung auf und lies nach. Keiner von uns kann ein perfektes Leben führen. Und um die Maßstäbe eines
            vollkommenen Gottes zu erreichen, müssten wir ein <u class="underline">fehlerloses Leben</u> führen.
            <br>
            Schon mal versucht, deine Fehler in den Griff zu bekommen? Bist du gescheitert? Ich auch. Gottes Maßstab,
            wirklich ausschließlich liebevoll miteinander umzugehen, erreichen wir nie. Darum entschloss sich Gott, dass
            er die <u class="underline">Wurzel unseres Unfähigkeitsproblems lösen</u> würde. Er kam als Mensch auf diese
            Welt und machte
            sich
            erlebbar, begreifbar und historisch überprüfbar. Jesus kam vor etwa 2000 Jahren auf diese Welt und führte
            das einzige fehlerfreie Leben der Geschichte. Trotzdem wurde er hingerichtet.
            <br>
            Dumm gelaufen? Nein. Mission erfüllt! Seine Hinrichtung war vielmehr ein Bezahlen unserer Schuld, denn die
            Strafe für die Sünde ist nach Gottes Logik der Tod und die Trennung von Gott. Jesus starb für dich, damit du
            ein <u class="underline">neues Leben</u> haben kannst. Weil Liebe einen Preis hat.
            <br>
            Sein Opfer gilt als Stellvertretertod für alle Schuld der Welt. <u class="underline">Auch für deine</u>,
            wenn du sie
            aufrichtig
            bereust und daran glaubst. Wenn wir das tun, verspricht uns Gott, dass er in unser Herz einziehen wird, mit
            uns wieder eine Beziehung hat und an uns arbeitet, damit wir mit seiner Hilfe zu vollständigen, liebevollen
            Menschen werden. Seine Kraft hilft uns dabei, uns zu verändern.
            <br>
            Und das Beste? Gott stellt uns eine <u class="underline">Zukunft ohne Kriege und Leid</u> in Aussicht. Wo
            der Mensch befreit
            von
            Bosheit in Frieden mit ihm Leben kann. Das wird wunderbar! Bist du dabei? Dann beginne doch deine Suche nach
            dem, der allein unser Problem an der Wurzel packen kann. Er sucht dich bereits.
          </p>

          <p class="solution-collapse" :class="{collapsed: !solutionExpanded}" @click="collapseSolution">Weniger
            dazu
            <span class="material-icons" style="transform: translateY(4px)">expand_less</span>
          </p>

        </div>
      </div>

      <div class="section section-3">
        <div class="section-content">
          <img id="nt" class="book" src="@/assets/nt.jpg">
          <div class="col">
            <h4 class="answers">Finde Antworten.</h4>
            <p class="text">Finde Antworten auf deine Fragen in dem Buch, in dem Gott sich vorstellt – in zeitgemäßer
              Sprache.</p>
            <button class="button" @click="orderNt = true">Gratis bestellen</button>
            <v-dialog v-model="orderNt">
              <Order selection="Neues Testament (Bibel)" :active="orderNt" @close="orderNt = false"/>
            </v-dialog>

          </div>
        </div>
      </div>

      <div class="section section-4">
        <div class="section-content">
          <img id="gfa" class="book" src="@/assets/gfa.jpg">
          <div class="col">
            <h4 class="help">Mit Stützrädern.</h4>
            <p class="text">Die Bibel an sich ist dir zu steil für den Anfang? Dann hol dir kostenlos das Buch Glaube
              für Anfänger von Maria Jäger und lass dich an das Thema heranführen.</p>
            <button class="button" @click="orderGfa = true">Gratis bestellen</button>
            <v-dialog v-model="orderGfa">
              <Order selection="Glaube für Anfänger" :active="orderGfa" @close="orderGfa = false"/>
            </v-dialog>
          </div>
        </div>
      </div>

      <div class="section section-5">
        <div class="section-content">
          <a href="https://wersglaubt.at" target="_blank"><img id="wg" src="@/assets/wg.jpg"></a>
          <div class="col">
            <h4 class="no-interest">Kein Bock?</h4>
            <p class="text">Dann sag uns deine Meinung auf <a href="https://wersglaubt.at"
                                                              target="_blank">WERSGLAUBT.AT</a>. Da darfst
              du uns ruhig herausfordern mit allen Argumenten, die du gegen Glauben und Gott findest.</p>
            <a href="https://wersglaubt.at" class="wersglaubt-link" target="_blank">
              <button class="button">Seite besuchen</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Vue from "vue";
import Component from "vue-class-component";
import gsap from "gsap";
import {CSSRulePlugin} from "gsap/CSSRulePlugin";
import Order from "@/components/Order.vue";

@Component({
  components: {Order}
})
export default class Home extends Vue {

  data() {
    return {
      solutionExpanded: false,
      orderGfa: false,
      orderNt: false,
    };
  }

  mounted() {
    this.addUnderlineSpans();
    this.animateTape();
  }

  animateTape() {

    /*
    gsap.fromTo(".heart", {
      scale: 1,
      translateX: "-50%",
      translateY: "-50%",
    }, {
      translateX: "-50%",
      translateY: "-50%",
      scale: 1.15,
      duration: 1,
      yoyo: true,
      ease: Power1.easeOut,
      repeat: -1
    });*/

    gsap.timeline({ // Tape
      scrollTrigger: {
        trigger: ".section-1",
        pin: true,
        start: 'top top',
        end: "+=600",
        scrub: true
      },
      defaults: {duration: 1, ease: 'none'}
    })
        .to(".tape", {width: "0%"})
    .set("#scroll-hint", {
      opacity: 0
    })

    gsap.timeline({ // Question
      scrollTrigger: {
        trigger: ".section-1",
        pin: false,
        start: 'bottom 70%',
        end: "+=350",
        scrub: true
      },
      defaults: {duration: 1, ease: 'none'}
    })
        .to("#question-question", {
          duration: 2,
          text: "Wie können wir Menschen die Ursache für Leid und Kriege an der Wurzel packen?"
        })

    for (let underline of document.getElementsByClassName("underline")) {
      gsap.timeline({
        scrollTrigger: {
          trigger: underline,
          pin: false,
          start: 'top 70%',
          end: "+=100",
          scrub: true
        },
        defaults: {duration: 1, ease: 'none'}
      })
          .fromTo(underline.querySelector("span"), {width: "0%"}, {width: "100%"})
    }

    gsap.timeline({ // NT
      scrollTrigger: {
        trigger: ".section-3",
        pin: false,
        start: 'top bottom',
        end: "+=600",
        scrub: true
      },
      defaults: {duration: 1, ease: 'none'}
    })
        .from("#nt", {left: "-50%", rotate: "-45deg"})

    gsap.timeline({ // GFA
      scrollTrigger: {
        trigger: ".section-4",
        pin: false,
        start: 'top bottom',
        end: "+=600",
        scrub: true
      },
      defaults: {duration: 1, ease: 'none'}
    })
        .from("#gfa", {left: "-50%", rotate: "-45deg"})

    gsap.timeline({ // Website
      scrollTrigger: {
        trigger: ".section-5",
        pin: false,
        start: 'top 90%',
        end: "+=300",
        scrub: true
      },
      defaults: {duration: 1, ease: 'none'}
    })
        .from("#wg", {right: "50%"})

    const longText = document.getElementById("solution-text-long");
    for (let bold of longText.getElementsByTagName("b")) {
      gsap.timeline({ // Make elements bold on scrolling
        scrollTrigger: {
          trigger: bold,
          pin: false,
          start: 'top 50%',
          end: "+=1",
          scrub: true
        },
        defaults: {duration: 1, ease: 'none'}
      })
          .from(bold, {fontWeight: 300})
    }
  }

  // Add span elements for underline animations
  addUnderlineSpans() {
    for (let el of document.getElementsByClassName("underline")) {
      if (!el.querySelector("span")) {
        el.appendChild(document.createElement("span"));
      }
    }
  }

  expandSolution() {
    this.$data.solutionExpanded = true;
  }

  collapseSolution() {
    this.$data.solutionExpanded = false;

    gsap.to(window, {duration: 1, scrollTo: ".solution"});
  }

}
</script>

<style lang="scss">
@import "../style/global.scss";

.home {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;

  @media screen and (max-width: 800px) {
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }

  @media screen and (max-width: 370px) {
    font-size: 14px;
  }

  h3 {
    color: black;
    font-weight: 500;
    font-size: 2.5em;
    margin: 0 0 0.6em;
  }

  h4 {
    font-weight: 500;
    font-size: 2em;
    margin: 0 0 0.6em;
  }

  p {
    margin: 0;
  }
}

.section {
  background-color: white;
}

.section-content {
  max-width: 1200px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 75px 7.5vw;
}

.section-3, .section-4, .section-5 {
  .section-content {
    display: flex;
    flex-wrap: wrap;


    .book {
      height: 230px;
      position: relative;

      box-shadow: 0 0px 10px 0px black;
    }

    .col {
      margin-left: 50px;
      min-width: 300px;
    }


    .button {
      margin-top: 30px;
    }

    @media screen and (max-width: 530px) { // Only 1 column
      justify-content: center;

      .book {
        margin-bottom: 30px;
      }

      .col {
        margin: 0;
      }

      #wg {
        position: relative !important;
        transform: translateX(-75%);
      }
    }
  }
}


$heart-height: 400px;
$heart-center: 45%;

.heart {
  height: $heart-height;
  //margin-top: calc(#{$height} * 0.5);
  //margin-bottom: calc(#{$height} * 0.3);
  position: relative;
  top: $heart-center;
  left: 50%;
  //transform: translate(-50%, -50%); // Overwritten by animation keyframes
  will-change: transform;
  animation: heart-pulse 1s ease infinite alternate;
}

.section-1 {
  display: block;
  background-color: $blue;
  position: relative;
  z-index: 3;
  height: calc(#{$heart-height} * 2);

  @media screen and (max-width: 600px) {
    height: 100vh;
  }

  .headline-container {
    position: absolute;
    top: $heart-center;
    left: 50%;
    transform: translate(-50%, -50%);

    .new-hope {
      position: relative;

      .tape {
        position: absolute;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: $yellow;
      }
    }

    h2 {
      color: white;
      font-family: "Source Code Pro", "Source Sans Pro", sans-serif;
      font-weight: 600;
      font-size: 4em;
      margin: 0;
    }
  }

  .scroll-hint {
    position: absolute;
    top: 90%;
    transform: translateY(-100%);
    color: white;

    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    span {
      display: block;
      width: 100%;
      text-align: center;

      &.hint {
        font-weight: 600;
      }

      &.icon {
        transform: translateY(-0.15em);
        font-size: 2em;
      }
    }
  }

  .question {
    position: absolute;
    top: 100%;
    width: 100%;
    text-align: center;

    padding: 0 5vw;


    h2 {
      width: 100%;

      color: white;

      text-decoration: underline;
      text-decoration-color: $yellow;
      text-decoration-thickness: 6px;
      text-underline-offset: 6px;
      text-decoration-skip: none;

      @media screen and (max-width: 600px) {
        text-decoration-thickness: 4px;
        text-underline-offset: 4px;
      }
    }
  }
}

.section-1-diagonal {
  display: block;
  background-color: $blue;
  height: 400px;
  width: 100%;
  transform: skewY(-6deg) translateY(250px);
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.section-2 {
  position: relative;
  padding-top: 350px;
  background-color: transparent;

  @media screen and (max-width: 600px) {
    padding-top: 300px;
  }

  .solution-text {
    font-size: 1.5em;
  }

  .solution-expand, .solution-collapse {
    margin-top: 20px;
    color: $blue;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.5em;

    &.expanded, &.collapsed {
      visibility: hidden;
    }
  }

  .solution-text-long {
    max-height: 0;
    transition: max-height 1s ease-in-out;
    overflow: hidden;

    &.expanded {
      max-height: 2500px;
    }

    br {
      display: block;
      content: " ";
      margin-bottom: 20px;
    }
  }

  box-shadow: 0 0px 15px 0px gray;
  z-index: 1;

}

.section-3 {
  background-color: $blue;

  .section-content {
    .answers {
      color: white;
    }

    .text {
      color: white;
    }
  }
}

.section-4 {
  position: relative;
  box-shadow: 0 0px 15px 0px gray;
}

.section-5 {
  position: relative;
  z-index: 2;

  .section-content {

    a {
      width: 155px;
      position: relative;

      #wg {
        height: 300px;
        max-height: 300px;
        max-width: 1000px;
        position: absolute;
        right: 0;
      }
    }


  }
}

.underline {
  position: relative;
  text-decoration: none;
  display: inline-block;

  span {
    content: " ";
    width: 100%;
    height: 6px;
    position: absolute;
    bottom: -3px;
    left: 0;
    background-color: $yellow;

    @media screen and (max-width: 600px) {
      height: 4px;
    }
  }
}

b {
  font-weight: 600;
}

@keyframes heart-pulse {

  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1.15);
    transform: translate(-50%, -50%) scale(1.15);
  }

}

</style>
