<template>
  <div class="orderContainer" data-app>
    <div v-if="active" class="overlay" @click="close"/>
    <div v-if="active" :class="{order: true, active: active, mobile: mobile()}">
      <img src="@/assets/closeIcon.svg" class="closeIcon" @click="close">
      <div v-show="!order" class="bookSelection">
        <h3 class="title">Ich hätte gerne ein kostenloses Exemplar von...</h3>
        <v-radio-group v-if="offerOptions" v-model="book">
          <v-radio v-for="book in books" :value="book.title">
            <template slot="label">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <img :src="getBookUrl(book)">
                    {{ book.title }}
                  </div>
                </template>
                <span v-html="book.shortDescription"></span>
              </v-tooltip>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div class="content">
        <v-btn class="modernButton" v-show="! order" @click="order = true">Jetzt bestellen</v-btn>
        <div class="bookPreview">
          <img :src="getBookUrl(bookObject)">
          <p class="title"><b>{{ bookObject.title }}</b>{{ bookAuthor }}</p>
        </div>
        {{ book.title }}
        <v-form v-model="formValid" class="data" v-if="order && ! submitted">
          <v-row wrap>
            <v-flex xs12 sm6>
              <v-text-field v-model="firstName" label="Vorname" :rules="[getRules().present]"/>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field v-model="lastName" label="Nachname" :rules="[getRules().present]"/>
            </v-flex>
          </v-row>
          <v-row wrap>
            <v-flex xs12>
              <v-text-field v-model="street" label="Strasse, Hausnummer"
                            :rules="[getRules().present]"/>
            </v-flex>
          </v-row>
          <v-row wrap>
            <v-flex xs6 sm4>
              <v-text-field v-model="zip" label="Postleitzahl" :rules="[getRules().present]"/>
            </v-flex>
            <v-flex xs6 sm4>
              <v-text-field v-model="city" label="Ort" :rules="[getRules().present]"/>
            </v-flex>
            <v-flex xs12 sm4>
              <v-select v-model="country" :items="countries" label="Land"
                        :rules="[getRules().required]"/>
            </v-flex>
          </v-row>
          <v-row>
            <v-flex xs12>
              <v-checkbox v-model="accepted" :rules="[getRules().checked]" class="agreement">
                <template slot="label">
                  <p>
                    Ja, ich möchte ein kostenloses Buch zugeschickt bekommen.<br>
                    Meine Daten werden <b>nicht</b> an Dritte weitergegeben und <b>nur für die
                    Abwicklung der Bestellung</b> gespeichert.
                  </p>
                </template>
              </v-checkbox>
            </v-flex>
          </v-row>
          <v-row justify="center" align="center">
            <v-flex>
              <vue-recaptcha :sitekey="captchaKey" badge="bottomright"
                             loadRecaptchaScript
                             ref="recaptcha"
                             style="display: inline-block"
                             @verify="submit">
                <v-btn :disabled="! isValid" class="button">Kostenlos bestellen</v-btn>
              </vue-recaptcha>
            </v-flex>
          </v-row>
        </v-form>
        <div v-if="submitted">
          <br>
          <p><b>Vielen Dank für Deine Bestellung!</b><br>
            Wir werden das Buch möglichst schnell versenden, sodass es schon bald bei Dir ankommen
            wird!</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import BasicComponent from "./BasicComponent";

const books = require("@/assets/books.json")

@Component({
  components: {VueRecaptcha}
})
export default class Order extends BasicComponent {

  @Prop(String) selection;

  data() {
    return {
      offerOptions: true,
      formValid: false,
      accepted: false,
      active: true,
      order: true,
      firstName: "",
      lastName: "",
      street: "",
      zip: "",
      city: "",
      country: null,
      countries: ["Österreich", "Deutschland"],
      error: null,
      submitted: false,
      book: null,
    };
  }

  created() {
    if (this.selection) {
      this.offerOptions = false;
      this.book = this.selection;
    }
  }

  mounted() {

  }

  open() {
    this.$data.active = true;
  }

  close() {
    //this.$data.active = false;
    this.$emit("close");
  }

  get books() {
    console.log("Books", books);
    if (!this.offerOptions) {
      return books.filter(b => b.title === this.book);
    }
    return books;
  }

  get bookObject() {
    return this.books[0];
  }

  get bookAuthor() {
    const book = this.bookObject;
    const author = book?.author;
    if (! author || author.length === 0) return "";
    return ", " + author;
  }

  getBookUrl(book) {
    return require("@/assets/" + book.imagePath);
  }

  submit(token) {
    console.log("Submitting");
    this.$refs.recaptcha.reset();
    if (!token) return;
    if (!this.isValid) {
      console.log("Not valid");
      this.$data.error = "Bitte fülle alle Felder aus";
      return;
    }
    console.log(this.book);
    axios.post("https://bestellung.wersglaubt.at/order.php", {
      "token": token,
      "books": this.book,
      "email": "bestellung@wersglaubt.at",
      "first_name": this.firstName,
      "last_name": this.lastName,
      "street": this.street,
      "zip": this.zip,
      "city": this.city,
      "country": this.country,
      "source": "weltfriede.com",
    }).then((response) => {
      this.submitted = true;
    }).catch((err) => {
      this.error = "Ein unbekannter Fehler ist aufgetreten. Bitte senden Sie Ihre Anfrage per Mail an <!--<a href='mailto:kontakt@wersglaubt.at?subject=WERSGLAUBT.AT Anfrage&body=" + this.text + "'>-->kontakt[AT]wersglaubt.at</a>. Danke für Ihr Verständnis!";
      console.error(err);
    });
  }

  get isValid() {
    console.log("Valid: ", this.$data.formValid);
    return this.$data.formValid === true;
  }

}
</script>

<style scoped lang="scss">

.orderContainer {

  .description {
    text-align: left;
    font-weight: 300;

    h3 {
      font-variant: normal;
      font-weight: 300;
      font-size: 28px;
      margin-bottom: 10px;
    }

    .author {
      font-size: 14px;
      margin-bottom: 25px;
    }

    .coverContainer {
      display: flex;

      .bookText {
        width: auto;
        display: inline-block;
        padding-right: 30px;
      }

      .cover {
        display: inline-block;
        height: 250px;
        transform: translateY(-50px);
        margin-bottom: -50px;
      }

      @media screen and (max-width: 680px) {
        display: flex;
        flex-wrap: wrap;

        .cover {
          order: 1;
          transform: none;
          margin: 0 auto;
        }

        .bookText {
          order: 2;
          margin-top: 25px;
        }

      }
    }

  }

  .bookPreview {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 150px;
    }

    .title {
      margin-left: 25px;
    }
  }

  .order {
    max-height: 90vh;
    overflow-y: scroll;
    width: 800px;
    max-width: 90vw;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2500;
    padding: 30px;
    box-sizing: border-box;

    background-color: white;

    font-size: 20px;

    &.mobile {
      padding-left: 10px;
      padding-right: 10px;
    }

    .closeIcon {
      position: absolute;
      top: 15px;
      right: 15px;
      height: 15px;

      &:hover {
        cursor: pointer;
      }
    }

    .data {
      padding: 20px;
    }

    .submitButton {
      margin-top: 15px;

      &[disabled="disabled"] {
        $color: grey;

        color: $color;

        border-color: $color;
        pointer-events: none;

        &:hover {
          background-color: white;
          cursor: not-allowed;
        }
      }
    }

  }

  .overlay {
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.flex {
  padding-left: 5px;
  padding-right: 5px;
}

</style>

<style lang="scss">

.bookSelection {

  $bookImgHeight: 100px;
  $marginBelowImage: 10px;

  .v-input--radio-group__input {
    flex-direction: row !important;
    flex-wrap: wrap !important;

    .v-radio {
      margin-top: calc(#{$bookImgHeight} + #{$marginBelowImage});
      position: relative;
      width: 100%;
      margin-bottom: 10px !important;
      justify-content: center;

      @media screen and (min-width: 500px) {
        width: 50%;
      }

      @media screen and (min-width: 750px) {
        width: 33.33%;
      }

      label {
        margin-right: 0;

        div {
          margin: 0 auto;
        }
      }

      img {
        height: $bookImgHeight;
        display: block;
        position: absolute;
        top: calc(-#{$marginBelowImage});
        left: 50%;
        transform: translate(-50%, -100%);
      }

      .v-input--selection-controls__input {
        margin-left: auto;
        margin-right: 5px;
      }
    }
  }
}

.orderContainer {
  label {
    font-variant: unset !important;
    font-variant-caps: unset !important;
    font-weight: 400 !important;
    overflow-wrap: break-spaces;

    max-width: 100%;
    margin-right: 0;

    p {
      padding-left: 15px;
      width: 100%;
      overflow-wrap: break-word;
      white-space: normal;

      a {
        display: inline;
      }
    }
  }

  input[type="text"] {
    padding: 0;
  }

  .v-text-field__slot {
    label {
      top: 0 !important;
    }
  }
}

.makeScrollable {
  -webkit-overflow-scrolling: touch;
}

.data {
  label {
  }
}

</style>
