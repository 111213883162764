import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from "vuetify";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { TextPlugin } from "gsap/TextPlugin";

import "@/style/fonts.scss"

import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/dist/vuetify.min.css'


gsap.registerPlugin(CSSRulePlugin, TextPlugin);

Vue.config.productionTip = false

const vuetify = new Vuetify(
    {
      options: {
        customProperties: true
      }
    }
);

Vue.use(Vuetify);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
