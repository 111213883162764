<template>
  <footer class="footer">
    <router-link :to="{name: 'impressum'}">
      Wer wir sind
    </router-link>
    <router-link :to="{name: 'datenschutz'}">
      Datenschutz
    </router-link>
    <a href="mailto:kontakt@wersglaubt_SPAMPROTECTION_.at" onclick="this.href=this.href.replace(/_SPAMPROTECTION_/,'')">
      Kontakt
    </a>
    <!--<p class="recaptcha">Wir benutzen reCAPTCHA von Google. Dies unterliegt der <a href="https://www.google.com/intl/en/policies/privacy/">Datenschutzerklärung</a> und den <a href="https://www.google.com/intl/en/policies/terms/">Nutzungsbedingungen</a> von Google.</p>-->
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
  max-width: 100vw;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 20px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 300;

    margin-left: 10px;
    margin-right: 10px;

    &:visited {
      color: black;
    }
  }

  .recaptcha {
    $fontSize: 10px;

    font-size: $fontSize;
    margin-top: 10px;

    a {
      font-size: $fontSize;
      font-weight: 600;
    }
  }
}
</style>
