<template>
  <div class="toolbar">
    <a href="/" class="site-name">weltfriede.com</a>
  </div>
</template>

<script>
export default {
  name: "Toolbar"
}
</script>

<style scoped lang="scss">

.toolbar {
  position: absolute;
  padding: 30px;
  z-index: 7;

  @media screen and (max-width: 600px) {
    padding: 20px;
  }

  .site-name {
    color: white;
    font-family: "Source Code Pro", "Source Sans Pro", sans-serif;
    font-weight: 600;
  }
}
</style>
