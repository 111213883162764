<template>
  <div id="app" data-app>
    <Toolbar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style lang="scss">
@import "style/global.scss";

html, body {
  margin: 0;
  overflow-x: hidden;
}

#app {
  font-family: "Source Sans Pro", sans-serif;
  max-width: 100vw;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.button {
  $height: 50px;

  color: black !important;
  background-color: white !important;
  font-variant: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;

  height: $height !important;
  border-radius: $height !important;
  border: 1px solid $blue !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  text-transform: none !important;
  letter-spacing: normal !important;

  transition: background-color 0.25s ease-in-out;

  &:hover &:not(:disabled) {
    cursor: pointer !important;
    background-color: $blue !important;
    color: white !important;
    border: 1px solid white !important;
  }

  &:disabled {
    cursor: not-allowed !important;
    background-color: rgba(0, 0, 0, 0.18) !important;
    border: 1px solid transparent !important;
  }
}
</style>
<script>
import Toolbar from "./components/Toolbar";
import Footer from "./components/Footer";
import Component from "vue-class-component";
import BasicComponent from "./components/BasicComponent";
import VueMatomo from "vue-matomo";
import Vue from "vue";

@Component({
  components: {Footer, Toolbar}
})
export default class App extends BasicComponent {

  created() {
    this.setupAnalytics();
  }

  setupAnalytics() {
    const router = this.$router;

    Vue.use(VueMatomo, {
      siteId: 4,
      host: "https://analytics.gngn.at",
      router: router,
      disableCookies: true,
    });
    // Do not use VueAnalytics
    return;
    Vue.use(VueAnalytics, {
      id: 'UA-223393754-1',
      /*
      fields: {
          clientId: result,
          storage: "none"
      },
       */
      router,
      debug: {
        enabled: false, // default value
        trace: false, // default value
        sendHitTask: true // default value
      },
      set: [
        /*
        {
            field: "userId",
            value: result
        },

         */
        {
          field: "anonymizeIp",
          value: true
        }
      ]
    });
  }

}
</script>
